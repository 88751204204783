var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
// @mui
import { Button, IconButton, MenuItem, TableCell, TableRow, Typography, } from '@mui/material';
import { fDate } from '../../../utils';
import Iconify from '../../../components/iconify';
import MenuPopover from '../../../components/menu-popover';
import ConfirmDialog from '../../../components/confirm-dialog';
import usePermission from '../../../hooks/usePermission';
import { useBoolean } from '../../../hooks/useBoolean';
import { usePopover } from '../../../components/custom-popover';
import { HOST_API_PUBLIC } from '../../../config-global';
export default function BookSaleTableRow(_a) {
    var row = _a.row, selected = _a.selected, onEditRow = _a.onEditRow, onSelectRow = _a.onSelectRow, onDeleteRow = _a.onDeleteRow, onViewRow = _a.onViewRow;
    var office = row.office, items = row.items, totalAmount = row.totalAmount, createdBy = row.createdBy, createdAt = row.createdAt, note = row.note;
    var show = useBoolean();
    var _b = usePopover(), open = _b.open, onOpen = _b.onOpen, onClose = _b.onClose;
    var _c = useState(false), openConfirm = _c[0], setOpenConfirm = _c[1];
    var _d = useState(null), openPopover = _d[0], setOpenPopover = _d[1];
    var handleOpenConfirm = function () {
        setOpenConfirm(true);
    };
    var handleCloseConfirm = function () {
        setOpenConfirm(false);
    };
    var handleOpenPopover = function (event) {
        setOpenPopover(event.currentTarget);
    };
    var handleClosePopover = function () {
        setOpenPopover(null);
    };
    //   const [openConfirm, setOpenConfirm] = useState(false);
    //   const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
    var _e = usePermission(), isSuperAdmin = _e.isSuperAdmin, hasPermissionViewBookStock = _e.hasPermissionViewBookStock, hasPermissionEditBookStock = _e.hasPermissionEditBookStock, hasPermissionDeleteBookStock = _e.hasPermissionDeleteBookStock;
    // check current user permissions
    var isAllowedToViewBookStock = isSuperAdmin || hasPermissionViewBookStock;
    var isAllowedToEditBookStock = isSuperAdmin || hasPermissionEditBookStock;
    var isAllowedToDeleteBookStock = isSuperAdmin || hasPermissionDeleteBookStock;
    var secondRender = (_jsxs(_Fragment, { children: [(items === null || items === void 0 ? void 0 : items.length) > 0 && (_jsxs(TableRow, { children: [_jsx(TableCell, {}), _jsx(TableCell, __assign({ sx: { backgroundImage: 'linear-gradient(to bottom, #F4F6F8 0%, #F4F6F8 100%)' } }, { children: "Book" })), _jsx(TableCell, __assign({ sx: { backgroundImage: 'linear-gradient(to bottom, #F4F6F8 0%, #F4F6F8 100%)' }, align: "center" }, { children: "Image" })), _jsx(TableCell, __assign({ sx: { backgroundImage: 'linear-gradient(to bottom, #F4F6F8 0%, #F4F6F8 100%)' }, align: "center" }, { children: "Level" })), _jsx(TableCell, __assign({ sx: { backgroundImage: 'linear-gradient(to bottom, #F4F6F8 0%, #F4F6F8 100%)' }, align: "center" }, { children: "Section" })), _jsx(TableCell, __assign({ sx: { backgroundImage: 'linear-gradient(to bottom, #F4F6F8 0%, #F4F6F8 100%)' } }, { children: "Unit Price" })), _jsx(TableCell, __assign({ sx: { backgroundImage: 'linear-gradient(to bottom, #F4F6F8 0%, #F4F6F8 100%)' } }, { children: "Quantity" }))] })), items === null || items === void 0 ? void 0 : items.map(function (value) {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                return (_jsxs(TableRow, { children: [_jsx(TableCell, {}), _jsx(TableCell, { children: ((_a = value === null || value === void 0 ? void 0 : value.book) === null || _a === void 0 ? void 0 : _a.name) || '_____' }), _jsx(TableCell, __assign({ align: "center" }, { children: ((_b = value === null || value === void 0 ? void 0 : value.book) === null || _b === void 0 ? void 0 : _b.image) ? (_jsx("div", __assign({ style: { maxWidth: 100, height: 100, margin: '0 auto' } }, { children: _jsx("img", { src: "".concat(HOST_API_PUBLIC, "/uploads/").concat((_c = value === null || value === void 0 ? void 0 : value.book) === null || _c === void 0 ? void 0 : _c.image), alt: ((_d = value === null || value === void 0 ? void 0 : value.book) === null || _d === void 0 ? void 0 : _d.name) || 'book', style: {
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                        borderRadius: '12px',
                                    }, crossOrigin: "anonymous" }) }))) : ('__________') })), _jsx(TableCell, __assign({ align: "center" }, { children: ((_f = (_e = value === null || value === void 0 ? void 0 : value.book) === null || _e === void 0 ? void 0 : _e.level) === null || _f === void 0 ? void 0 : _f.name) || '__________' })), _jsx(TableCell, __assign({ align: "center" }, { children: ((_h = (_g = value === null || value === void 0 ? void 0 : value.book) === null || _g === void 0 ? void 0 : _g.section) === null || _h === void 0 ? void 0 : _h.name) || '__________' })), _jsx(TableCell, __assign({ align: "left" }, { children: value === null || value === void 0 ? void 0 : value.salePrice })), _jsx(TableCell, __assign({ align: "center" }, { children: value.quantitySold }))] }));
            })] }));
    //   const handleOpenConfirm = () => {
    //     setOpenConfirm(true);
    //   };
    //   const handleCloseConfirm = () => {
    //     setOpenConfirm(false);
    //   };
    //   const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    //     setOpenPopover(event.currentTarget);
    //   };
    //   const handleClosePopover = () => {
    //     setOpenPopover(null);
    //   };
    //   const color = getColor(amount);
    return (_jsxs(_Fragment, { children: [_jsxs(TableRow, __assign({ hover: true, selected: selected }, { children: [_jsx(TableCell, { children: (office === null || office === void 0 ? void 0 : office.name) || '_____' }), _jsx(TableCell, __assign({ align: "left" }, { children: _jsx(IconButton, __assign({ color: open ? 'inherit' : 'default', onClick: function () { return show.onToggle(); } }, { children: _jsx(Iconify, { icon: "solar:alt-arrow-down-line-duotone" }) })) })), _jsx(TableCell, { children: _jsx(Typography, __assign({ variant: "subtitle2", noWrap: true, sx: {
                                fontSize: '0.875rem',
                                background: 'red',
                                borderRadius: '.6rem',
                                textAlign: 'center',
                                width: 'fit-content',
                                padding: '0.5rem 1rem',
                                margin: 'auto',
                                color: 'white',
                            } }, { children: totalAmount })) }), _jsx(TableCell, __assign({ align: "center" }, { children: (createdBy === null || createdBy === void 0 ? void 0 : createdBy.name) || '_____' })), _jsx(TableCell, __assign({ align: "center" }, { children: note || '_____' })), _jsx(TableCell, __assign({ align: "center" }, { children: fDate(createdAt) })), _jsx(TableCell, __assign({ align: "center" }, { children: _jsx(IconButton, __assign({ color: openPopover ? 'inherit' : 'default', onClick: handleOpenPopover }, { children: _jsx(Iconify, { icon: "eva:more-vertical-fill" }) })) }))] })), show.value && secondRender, _jsxs(MenuPopover, __assign({ open: openPopover, onClose: handleClosePopover, arrow: "right-top", sx: { width: 140 } }, { children: [isAllowedToViewBookStock && (_jsxs(MenuItem, __assign({ onClick: function () {
                            onViewRow();
                            handleClosePopover();
                        } }, { children: [_jsx(Iconify, { icon: "carbon:view-filled" }), "View"] }))), isAllowedToEditBookStock && (_jsxs(MenuItem, __assign({ onClick: function () {
                            onEditRow();
                            handleClosePopover();
                        } }, { children: [_jsx(Iconify, { icon: "eva:edit-fill" }), "Edit"] })))] })), _jsx(ConfirmDialog, { open: openConfirm, onClose: handleCloseConfirm, title: "Delete", content: "Are you sure want to delete?", action: _jsx(Button, __assign({ variant: "contained", color: "error", onClick: function () {
                        onDeleteRow();
                        handleCloseConfirm();
                    } }, { children: "Delete" })) })] }));
}
